<template>
    <div class="tamanhoPopUp"> 
            <ag-grid-vue
                ref="agGridTable"        
                :gridOptions="configuracaoGrid.gridOptions"
                class="ag-grid-table ag-grid-altura mb-2"
                :columnDefs="configuracaoGrid.columnDefs"
                :defaultColDef="configuracaoGrid.defaultColDef"
                :rowData="dadosDaGrid"
                :headerHeight="25"
                :rowHeight="25"
                :suppressHorizontalScroll="true"
                rowSelection="single"                                
                colResizeDefault="shift"
                @grid-ready="onGridReady"
                @row-selected="onRowSelectedRegistro"    
                :animateRows="true"
                :floatingFilter="false"
                :pagination="false"                
                :paginationAutoPageSize="true"
                :suppressPaginationPanel="true">
            </ag-grid-vue>             
    </div>
</template>

<script>
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/concept/scss/tabelaBasica.scss'

const URL_SERVLET = "/MotoristasRest";

export default {    
    components: {
        AgGridVue
    }, 
    props: {
        idCliente: {
            type: Number,
            required: false
        }       
    },
    beforeMount() {
        this.configuracaoGrid.gridOptions = {};
        this.configuracaoGrid.columnDefs = [        
            { headerName: 'NOME', field: 'nome' },
            { headerName: 'CPF',  field: 'cpf'  },            
        ];
        this.configuracaoGrid.defaultColDef = { sortable: true, resizable: true, suppressMenu: true  };
        this.configuracaoGrid.tipoSelecaoGrid = this.tipoSelecao;
    },
    mounted() {
        this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;       
        this.configuracaoGrid.gridColumnApi = this.configuracaoGrid.gridOptions.columnApi;                    
    },
    data() {
        return {
            configuracaoGrid: { 
                gridApi: null, gridColumnApi: null, gridOptions: null, 
                defaultColDef: null,
                columnDefs: null,
                tipoSelecaoGrid: null
            },
            dadosDaGrid: [], 
            motoristasSelecionados: []           
        }
    },    
    methods: {
        fetchLista() {      
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('idCliente', this.idCliente);
            params.append('motoristaAtivo', true); 
            
            axios.post(URL_SERVLET, params, 
                    { credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) { // Efetuar logoff                    
                    this.$router.push({ path: '/login' })
                } else {
                    this.dadosDaGrid = response.data;     
                    this.configuracaoGrid.gridApi.setRowData(this.dadosDaGrid);                  
                    this.configuracaoGrid.gridApi.sizeColumnsToFit();  
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */   console.log("EFETUAR LOGOFF! - " + error);
            })                  
        },
        onRowSelectedRegistro() {                  
            this.motoristasSelecionados = null;
            this.motoristasSelecionados = [];
            let self_ = this;
            var selectedRows = this.configuracaoGrid.gridApi.getSelectedRows();
            selectedRows.forEach(function(selectedRow) {
                self_.motoristasSelecionados.push(selectedRow);
            });                
            this.$emit('selecionarMotorista', this.motoristasSelecionados);            
        },
        onGridReady() {      
            this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;       
            this.configuracaoGrid.gridColumnApi = this.configuracaoGrid.gridOptions.columnApi;                          
            this.fetchLista();           
        }        
    }    
}
</script>

<style lang="scss" scoped>
  .ag-grid-altura {       
    height: 340px; 
  }

  .tamanhoPopUp {
    height: 370px;       
  }  
</style>